import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MonthSelector from './MonthSelector';
import { subMonths } from 'date-fns';
import useAPIRequest from '../../hooks/useAPIRequest';
import MonthlySalariesResource from '../../api/resources/salaries/MonthlySalariesResource';
import { dateToString } from '../../utils/dateUtils';
import { MonthlySalariesFindByDateResponse, SalaryNoteType } from './types';
import TeamMemberSalariesList from './teamMemberSalaries/TeamMemberSalariesList';
import ProjectsForSalariesTable from './projectsForSalaries/ProjectsForSalariesTable';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import groupBy from 'lodash/groupBy';
import { TeamMember } from '../team/types';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import { OCCUPATION_RATE_OVERHEAD_ROLES } from '../settings/keys';
import { TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE } from '../team/constants';
import TeamMemberSalaryNotesResource from '../../api/resources/salaries/TeamMemberSalaryNotesResource';

const END_DAY_TO_SHOW_PREVIOUS_MONTH_SALARIES = 15;

const SalariesTab = () => {
  const [showOverheadRoles, setShowOverheadRoles] = useState<boolean>(false);

  const [month, setMonth] = useState<Date>(
    new Date().getDate() <= END_DAY_TO_SHOW_PREVIOUS_MONTH_SALARIES
      ? subMonths(new Date().setDate(15), 1)
      : new Date(new Date().setDate(15))
  );

  const {
    data: settingsResponse,
    performRequest: fetchSetting,
  } = useAPIRequest(ProjectSettingResource.findByKey);

  const {
    data: monthlySalariesResponse,
    performRequest: getMonthlySalaries,
  } = useAPIRequest(MonthlySalariesResource.findByDate);

  const {
    data: teamMembersNotes,
    performRequest: getTeamMemberNotes,
  } = useAPIRequest(TeamMemberSalaryNotesResource.list);

  const fetchSalaries = useCallback(() => {
    getMonthlySalaries(dateToString(month), {
      filter: {
        include: [
          { relation: 'teamMemberMonthlySalaries' },
          { relation: 'monthlyProjectBonuses' },
          { relation: 'monthlyProjectBonusNotes' },
        ],
      },
    });
    getTeamMemberNotes({
      filter: {
        where: {
          type: SalaryNoteType.GENERAL,
        },
      },
    });
  }, [getMonthlySalaries, month, getTeamMemberNotes]);

  useEffect(() => {
    fetchSetting(OCCUPATION_RATE_OVERHEAD_ROLES);
  }, [fetchSetting]);

  useEffect(() => {
    fetchSalaries();
  }, [fetchSalaries]);

  const {
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  const fetchTeamMembers = useCallback(() => {
    getTeamMembers({ filter: { order: ['role ASC', 'name ASC'] } });
  }, [getTeamMembers]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  const responseData = monthlySalariesResponse?.data as MonthlySalariesFindByDateResponse;

  const salariesById = useMemo(() => {
    return groupBy(
      responseData?.monthlySalaries?.teamMemberMonthlySalaries || [],
      'teamMemberId'
    );
  }, [responseData]);

  const filteredTeamMembers: TeamMember[] = useMemo(() => {
    const teamMembers = teamMembersResponse?.data || [];
    return teamMembers.filter(
      (teamMember: TeamMember) => salariesById[teamMember.id]
    );
  }, [teamMembersResponse, salariesById]);

  const teamMembersWithoutOverheadRoles = useMemo(() => {
    return filteredTeamMembers.filter(
      (teamMember: TeamMember) =>
        !settingsResponse?.data.value.includes(teamMember.role)
    );
  }, [filteredTeamMembers, settingsResponse]);

  return (
    <>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <MonthSelector
          month={month}
          setMonth={setMonth}
          disableNext={!responseData?.hasNext}
          disablePrevious={!responseData?.hasPrevious}
        />
      </div>
      <div>
        {responseData &&
          !responseData?.monthlySalaries.skipPerformanceComponent && (
            <ProjectsForSalariesTable
              monthlySalaries={responseData}
              teamMembers={teamMembersWithoutOverheadRoles}
              refetchSalaries={fetchSalaries}
            />
          )}
      </div>
      <div>
        <TeamMemberSalariesList
          refetchSalaries={fetchSalaries}
          monthlySalaries={responseData}
          teamMembers={(showOverheadRoles
            ? filteredTeamMembers
            : teamMembersWithoutOverheadRoles
          ).filter(
            (teamMember) =>
              teamMember.employmentType !==
                TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE ||
              salariesById[teamMember.id][0].performanceComponent
          )}
          salariesById={salariesById}
          refetchTeamMembers={fetchTeamMembers}
          teamMemberNotes={teamMembersNotes?.data || []}
          showOverheadRoles={showOverheadRoles}
          toggleOverheadRoles={() => {
            setShowOverheadRoles((prev) => !prev);
          }}
        />
      </div>
    </>
  );
};

export default SalariesTab;
